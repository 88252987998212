import React from "react";
import Layout from "../../components/layout";
import FinancialServices from "../../components/views/industries/financial-services";

const FinancialServicesPage = () => {
  return (
    <Layout>
      <FinancialServices />
    </Layout>
  );
};

export default FinancialServicesPage;

import React from "react";
import HeaderBackground from "../../../common/HeaderBackground";
import AboutSection from "../../../common/AboutSection";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import CallToAction from "../../../common/CallToAction";
import MetaData from "../../../common/MetaData";
import { metaData } from "../../../../config"
const FinancialServices = () => {
 const data = useStaticQuery(graphql`
   {
     allStrapiFinancialHeaders {
       nodes {
         title
         titleSlogan
         description
         aboutTitle
         aboutDescription
         sectionsList {
           sectionTitle
           description
           image {
             childImageSharp {
               gatsbyImageData(quality: 80, placeholder: NONE, width: 540)
             }
           }
         }
       }
     }
   }
 `);
 const {
 title,
 titleSlogan,
 description,
 aboutTitle,
 aboutDescription,
 sectionsList
 } = data.allStrapiFinancialHeaders.nodes[0];
 
 return (
 <>
 <MetaData pageTitle={title} description={metaData.financial.description} keyword={metaData.financial.keyword} />
 <section className="section position-relative pt-6 pb-2">
 {/* <HeaderBackground background={Background} /> */}
 {/* For testing */}
 <HeaderBackground backgroundClass = {"industry-financial-bg"}/>
 {/* For testing */}
 <div className="container overflow-hidden h-100 mt-4">
 <div className="row">
 <div className="col-md-10 col-lg-10 py-4 m-auto text-center">
 <h1 className="display-4 text-contrast bold">
 {title}
 <span className="d-block light font-md">{titleSlogan}</span>
 </h1>
 </div>
 </div>
 </div>
 </section>
 <section className="section">
 <div className="container py-5">
 <div className="section-heading mb-2">
 <ReactMarkdown
 source={description}
 className="lead font-regular font-weight-normal"
 />
 </div>
 </div>
 </section>
 <AboutSection
 aboutData={{ aboutTitle, aboutDescription, sectionsList }}
 />
 <CallToAction tag={title.toLowerCase().trim()} />
 </>
 );
};
 
export default FinancialServices;